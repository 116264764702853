import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { EyeIcon, EyeClosedIcon } from "../utils/icons.jsx";
import {users} from './utils/users.tsx'; // Importa os dados dos usuários

import './index.css';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    // Verifica se o usuário e a senha estão corretos (a partir do JSON)
    const validUser = users.find(user => user.username === username && user.password === password);
    if (validUser) {
      // Chama a função onLogin passada por props para atualizar o estado de login
      onLogin(true);
      // Define o estado de login no localStorage
      localStorage.setItem('isLoggedIn', 'true');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro no Login',
        text: 'Credenciais inválidas',
      });
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Inverte o estado para mostrar/ocultar a senha
  };

  return (
    <div className='container_login'>
      <header className='header_login'>
        <img className='logo_focal' src="https://focaltextil.com.br/wp-content/uploads/2021/02/FOCAL-TEXTIL-LOGO.png" alt="Focal Textil Logo" />
      </header>
      <footer className='footer_login'>
        <div className='title_login'>Login - Tabela de Preços</div>
        <div>
          <label>
            <input className='input_name' type="text" value={username} autoCapitalize="off" onChange={(e) => setUsername(e.target.value)} placeholder='Usuário' />
          </label>
        </div>
        <div>
          <label>
            <input type={showPassword ? 'text' : 'password'} autoCapitalize="off" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Senha' />
            <button className='button_showPassword' onClick={toggleShowPassword}>
              {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
            </button>
          </label>
        </div>
        <button className='button_login' onClick={handleLogin}>Entrar</button>
      </footer>
    </div>
  );
}

export default Login;
