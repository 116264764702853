import React from 'react';
import { LogoutIcon } from '../../../utils/icons';
import './index.css'

const UserInfo = ({ titleSheet, lastChange, handleLogout }) => {
    return (
        <div className="container container_user">
            <div className="buttons_action">
                <div>
                    <h2>{titleSheet}</h2>
                    <span className='last-change'>Ultima atualização: {lastChange}</span>
                </div>
                <button className='button_logout button' onClick={handleLogout}>Sair <LogoutIcon /></button>
            </div>
        </div>
    );
};

export default UserInfo;
