// MobileMenu.js
import React from 'react';
import AutoCompleteForm from '../../../AutoCompletForm';
import './index.css'

const MobileMenu = ({ isMenuOpen, handleCloseMenu, jsonData, measures, addToCart }) => {
    return (
        isMenuOpen && (
            <div className="mobile_menu">
                <header>
                    <h3>Materiais</h3>
                    <button className="close-menu" onClick={handleCloseMenu} aria-label="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </header>
                <div className='buttons-material'>
                    {jsonData.map((item, index) => (
                        <div className='item-select' key={index}>
                            <AutoCompleteForm
                                data={item.data}
                                sheetName={item.sheetName}
                                index={index}
                                measures={measures}
                                addToCart={addToCart}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    );
};

export default MobileMenu;
