// useScrollPosition.js
import { useState, useEffect } from 'react';

const useScrollPosition = () => {
    const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1000) {
                setShouldShowBackToTop(true);
            } else {
                setShouldShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return shouldShowBackToTop;
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export {useScrollPosition, scrollToTop};
