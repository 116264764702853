// useLogout.js
import { useEffect, useCallback } from 'react';

const useLogout = (onLogout) => {
    const handleLogout = useCallback(() => {
        localStorage.removeItem('userName');
        localStorage.removeItem('userCreated');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('clientName');
        onLogout(false);
    }, [onLogout]);

    useEffect(() => {
        const handleLogoutClick = () => {
            handleLogout();
        };

        window.addEventListener('logout', handleLogoutClick);

        return () => {
            // Limpar qualquer evento de logout anterior
            window.removeEventListener('logout', handleLogoutClick);
        };
    }, [handleLogout]);

    return handleLogout;
};

export default useLogout;
