import { useState, useEffect } from 'react';

const useAuthValidation = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    const lastLoginTime = localStorage.getItem('lastLoginTime');

    if (loggedInStatus === 'true' && lastLoginTime) {
      const lastLoginTimestamp = new Date(lastLoginTime).getTime();
      const currentTime = new Date().getTime();
      const cacheExpiryTime = 6 * 60 * 60 * 1000; // 6 horas em milissegundos
      // const cacheExpiryTime = 1 * 60 * 1000; // 6 horas em milissegundos

      // Verifica se passaram mais de 6 horas desde o último login
      if (currentTime - lastLoginTimestamp > cacheExpiryTime) {
        setIsLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('lastLoginTime');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('clientName');
        return;
      }
    }

    setIsLoggedIn(loggedInStatus === 'true');
  }, []);

  return isLoggedIn;
};

export default useAuthValidation;
