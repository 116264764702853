import React from 'react';

import './index.css'

const CartButtons = ({ cartItems, clearCart, handlePDF, handleClearInput }) => {
    const handleClearCart = () => {
        clearCart();
        handleClearInput(); // Chama a função de limpar o input
    };

    return (
        <div>
            <button disabled={!cartItems.length} className="button_clear_cart button" title="Limpar Orçamento" onClick={handleClearCart}>Limpar Orçamento</button>
            <button disabled={!cartItems.length} className="button_print button" title="Gerar pdf" onClick={handlePDF}>Gerar PDF</button>
        </div>
    );
};

export default CartButtons;
