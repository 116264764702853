import React from 'react';
import Login from './components/Login';
import Prices from './components/Prices/index';
import useAuthValidation from './components/Prices/hooks/useLoginTime/index.tsx';

function App() {
  const isLoggedIn = useAuthValidation();

  const handleLogin = () => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('lastLoginTime', new Date().toISOString());
    window.location.reload(); // Reload da página para garantir que o componente Prices seja renderizado corretamente após o login
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('lastLoginTime');
    window.location.reload(); // Reload da página para garantir que o componente Login seja renderizado corretamente após o logout
  };

  if (isLoggedIn === null) {
    return <div>Verificando estado de login...</div>;
  } else if (isLoggedIn) {
    return <Prices onLogout={handleLogout} />;
  } else {
    return <Login onLogin={handleLogin} />;
  }
}

export default App;
