// useMenu.js
import { useEffect } from 'react';

const useMenu = (isMenuOpen, setIsMenuOpen) => {
    useEffect(() => {
        const handleBodyOverflow = () => {
            if (isMenuOpen) {
                document.body.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
            }
        };

        handleBodyOverflow();

        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [isMenuOpen]);

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    return { handleCloseMenu };
};

export default useMenu;
