// useGoogleSheetsData.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

const useGoogleSheetsData = (spreadsheetId, apiKey) => {
    const [jsonData, setJsonData] = useState(null);
    const [measures, setMeasures] = useState([]);
    const [titleSheet, setTitleSheet] = useState('');
    const [lastChange, setLastChange] = useState('');

    useEffect(() => {
        const fetchGoogleSheetsData = async () => {
            try {
                const cachedData = localStorage.getItem('cachedGoogleSheetsData');
                const cachedTimestamp = localStorage.getItem('cachedTimestamp');
                const firstData = localStorage.getItem('firstData');
                const title = localStorage.getItem('titleSheet');
                const lastChange = localStorage.getItem('lastChange');

                if (cachedData && cachedTimestamp) {
                    const currentTime = new Date().getTime();
                    const cacheExpiryTime = 6 * 60 * 60 * 1000; // 6 horas em milissegundos
                    // const cacheExpiryTime = 2 * 60 * 1000; // 2 minutos

                    if (currentTime - parseInt(cachedTimestamp) < cacheExpiryTime) {
                        const parsedData = JSON.parse(cachedData);
                        setLastChange(lastChange);
                        setTitleSheet(title);
                        setMeasures(firstData.split(','))
                        setJsonData(parsedData);
                        return;
                    }
                }

                const sheetsResponse = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}?key=${apiKey}`
                );

                const sheetNames = sheetsResponse.data.sheets.map(sheet => sheet.properties.title);
                const responseDateChange = await axios.get(
                    `https://www.googleapis.com/drive/v3/files/${spreadsheetId}?key=${apiKey}&fields=modifiedTime`
                );

                const separatedData = [];

                for (const sheetName of sheetNames) {
                    const apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?alt=json&key=${apiKey}`;
                    const response = await axios.get(apiUrl);

                    localStorage.setItem('firstData', response.data.values[0].slice(2));
                    setMeasures(response.data.values[0].slice(2))
                    for (let i = 1; i < response.data.values.length; i++) {
                        const record = response.data.values[i];
                        const key = record[0];
                        if (!separatedData[key]) {
                            separatedData[key] = [];
                        }

                        separatedData[key].push(record);
                    }
                }

                const allDataFromSeparated = Object.entries(separatedData).map(([sheetName, data]) => {
                    return { sheetName, data: data };
                });

                localStorage.setItem('titleSheet', sheetsResponse.data.properties.title);
                localStorage.setItem('lastChange', format(new Date(responseDateChange.data.modifiedTime), 'dd/MM/yyyy HH:mm:ss'));
                localStorage.setItem('cachedGoogleSheetsData', JSON.stringify(allDataFromSeparated));
                localStorage.setItem('cachedTimestamp', new Date().getTime().toString());

                setLastChange(format(new Date(responseDateChange.data.modifiedTime), 'dd/MM/yyyy HH:mm:ss'));
                setJsonData(allDataFromSeparated);
                setTitleSheet(sheetsResponse.data.properties.title);

            } catch (error) {
                console.error('Erro ao buscar dados da planilha do Google Sheets', error);
            }
        };

        fetchGoogleSheetsData();
    }, [spreadsheetId, apiKey]);

    return { jsonData, measures, titleSheet, lastChange };
};

export default useGoogleSheetsData;

