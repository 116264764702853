// usePDFGeneration.js
import { useState } from 'react';
import Swal from 'sweetalert2';
import html2pdf from 'html3pdf';

const usePDFGeneration = (printableContentRef) => {
    const [generatePDF, setGeneratePDF] = useState(false);

    const handlePDF = () => {
        setGeneratePDF(true);
        const clientName = localStorage.getItem('clientName')
        const titlePDF = clientName ? `Orçamento-Focal-${clientName.replace(/\s+/g, '-')}.pdf` : `Orçamento-Focal.pdf`

        Swal.fire({
            text: 'Gerando PDF, aguarde...',
            showConfirmButton: false
        });

        const options = {
            margin: [0.2, 0.5, 0.8, 0.5],
            filename: titlePDF,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, scale: 2, letterRendering: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pageBreak: { mode: 'legacy' },
        };

        try {
            setTimeout(() => {
                const printableContent = printableContentRef.current;
                printableContent.style.minHeight = '300px';

                const pdf = html2pdf().from(printableContent).set(options);
                pdf.save();
                setGeneratePDF(false);
                Swal.fire({
                    icon: 'success',
                    title: 'PDF gerado com sucesso',
                    showConfirmButton: false,
                    showCancelButton: true,
                    confirmButtonColor: "#0578bd",
                    cancelButtonText: 'Fechar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setGeneratePDF(false);
                    }
                });
            }, 2000);
        } catch (error) {
            setGeneratePDF(false);
            Swal.fire({
                icon: 'error',
                title: 'Erro ao gerar o PDF',
                text: 'Ocorreu um erro ao tentar gerar o PDF.',
            });
        }
    };

    return { generatePDF, handlePDF };
};

export default usePDFGeneration;
