import React, { useState, useEffect } from 'react'

import './index.css';

export const CalculateTotal = ({ cartItems, isMobile, generatePDF }) => {
    const [discountPercentage, setDiscountPercentage] = useState(0);
    let valueTab1 = 0;
    let valueTab2 = 0;

    useEffect(() => {
        const storedDiscountPercentage = localStorage.getItem('discountPercentage');

        // Se houver um valor armazenado, inicialize o estado com esse valor
        if (storedDiscountPercentage && storedDiscountPercentage !== null) {
            setDiscountPercentage(parseFloat(storedDiscountPercentage));
        }
    }, []);

    cartItems.forEach(item => {
        // Função para limpar valores monetários e converter em números
        const cleanAndParsePrice = (price) => {
            // Se o preço for uma string vazia, retorna 0
            if (price === "") {
                return 0;
            }
            // Remover "R$" e substituir vírgulas por pontos
            const numericString = price.replace("R$", "").replace(".", "").replace(",", ".");
            // Converter para número
            return parseFloat(numericString) || 0;
        };

        // Remover "R$" e converter para número
        const priceIndex2 = cleanAndParsePrice(item.prices[2]);
        const priceIndex3 = cleanAndParsePrice(item.prices[3]);

        valueTab1 += priceIndex2;
        valueTab2 += priceIndex3;
    });

    const discountAmount1 = valueTab1 * (1 - discountPercentage / 100);
    const discountAmount2 = valueTab2 * (1 - discountPercentage / 100);

    // Formatando os valores para o formato de moeda brasileira
    const formattedValueTab1 = valueTab1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const formattedValueTab2 = valueTab2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const formatedDiscountAmount1 = discountAmount1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const formatedDiscountAmount2 = discountAmount2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    const handleDiscountChange = (e) => {
        const inputValue = e.target.value.trim();
        let percentage = 0;

        if (inputValue !== '') {
            percentage = parseFloat(inputValue);
            if (isNaN(percentage)) {
                percentage = 0;
            }
        }

        localStorage.setItem('discountPercentage', percentage.toString());
        setDiscountPercentage(percentage);
    };

    return (!isMobile ?
        <>
            <div className={!generatePDF ? 'totalizer list-price' : 'totalizer list-price hide-pdf'}>
                <div className="price_row">
                    <div className="measure">LARGURA</div>
                    <div className="price show">Subtotal:</div>
                </div>
                <div className="price_row">
                    <div className="measure">GRAMATURA</div>
                    <div className="price"></div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 1</div>
                    <div className="price">{formattedValueTab1}</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 2</div>
                    <div className="price">{formattedValueTab2}</div>
                </div>
                <div className="price_row">
                    <div className="measure">UN</div>
                    <div className="price"></div>
                </div>
                {!generatePDF && (<button></button>)}
            </div>
            <div className={!generatePDF ? 'totalizer discount' : 'totalizer discount hide-pdf'}>
                <div className="price_row">
                    <div className="measure show">Desconto (%):</div>
                    <input type="number" value={discountPercentage > 0 ? discountPercentage : null} min='0' max='100' onChange={handleDiscountChange} />
                </div>
            </div>
            <div className={generatePDF ? 'totalizer best-price border' : 'totalizer best-price'}>
                <div className="price_row">
                    <div className="measure">LARGURA</div>
                    <div className="price show">TOTAL:</div>
                </div>
                <div className="price_row">
                    <div className="measure">GRAMATURA</div>
                    <div className="price"></div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 1</div>
                    <div className="price">{formatedDiscountAmount1}</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 2</div>
                    <div className="price">{formatedDiscountAmount2}</div>
                </div>
                <div className="price_row">
                    <div className="measure">UN</div>
                    <div className="price"></div>
                </div>
                {!generatePDF && (<button></button>)}
            </div>

            <div className='description-totalizer'>Orçamento valido por 7 dias.</div>
        </>
        :
        <>
            <div className={!generatePDF ? 'totalizer' : 'totalizer hide-pdf'}>
                <div className="price_row">
                    <div className="price show">Subtotal:</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 1</div>
                    <div className="price">{formattedValueTab1}</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 2</div>
                    <div className="price">{formattedValueTab2}</div>
                </div>
            </div>
            <div className={!generatePDF ? 'totalizer discount' : 'totalizer discount hide-pdf'}>
                <div className="price_row">
                    <div className="measure show">Desconto (%):</div>
                    <input type="number" min='0' max='100' onChange={handleDiscountChange} />
                </div>
            </div>
            <div className={generatePDF ? 'totalizer border' : 'totalizer'}>
                <div className="price_row">
                    <div className="price show">TOTAL:</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 1</div>
                    <div className="price">{formatedDiscountAmount1}</div>
                </div>
                <div className="price_row">
                    <div className="measure show">PREÇO: TAB 2</div>
                    <div className="price">{formatedDiscountAmount2}</div>
                </div>
            </div>

            <div className='description-totalizer'>Orçamento valido por 7 dias.</div>
        </>

    );
}
