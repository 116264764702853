import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import "./index.css"

const AutoCompleteForm = ({ firstData, data, sheetName, index, measures, addToCart }) => {
    const [selectedValues, setSelectedValues] = useState([{ value: null, label: `${sheetName}` }]);

    useEffect(() => {
        setSelectedValues([{ value: null, label: `${sheetName}` }]);
    }, [data, sheetName]);

    const handleInputChange = (selectedOption, rowIndex) => {
        setSelectedValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[rowIndex] = selectedOption;
            return updatedValues;
        });
    };

    const addSelectedToCart = () => {
        selectedValues.forEach(selectedValue => {
            if (selectedValue.value !== null) {
                const rowIndex = selectedValue.value;
                const productName = data[rowIndex][1];
                const prices = data[rowIndex].slice(2);
                addToCart({ sheetName, rowIndex, productName, measures, prices }); // Passando measures para addToCart
            }
        });

        setSelectedValues([{ value: null, label: `${sheetName}` }]);
    };

    const isSelecting = () => {
        return selectedValues.some(value => value.value !== null);
    };

    return (
        <div className='item-search'>
            <table className={index === 0 ? 'table-item first' : 'table-item'}>
                <tbody>
                    {selectedValues.map((selectedValue, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <Select
                                    onChange={(option) => handleInputChange(option, rowIndex)}
                                    options={data.map((row, rowIndex) => (
                                        row.length > 0 ? {
                                            value: rowIndex,
                                            label: row[1],
                                        } : null
                                    )).filter(option => option !== null)}
                                    isSearchable
                                    placeholder={`Search for ${sheetName}...`}
                                    value={selectedValue}
                                    className='select-item'
                                    menuPlacement="auto"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isSelecting() && (
                <div>
                    <button className='button-add-to-cart button' title="Adicionar ao orçamento" onClick={addSelectedToCart}>Adicionar ao Orçamento</button>
                </div>
            )}
        </div>
    );
};

export default AutoCompleteForm;
