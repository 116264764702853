import React, { useState, useRef } from 'react';

import AutoCompleteForm from '../AutoCompletForm';
import { RemoveIcon } from '../utils/icons.jsx';
import { CalculateTotal } from './hooks/useCalculateTotal'
import LoadingComponent from './components/LoadingComponent';
import useMobileScreen from './hooks/useMobileScreen';
import { useScrollPosition, scrollToTop } from './hooks/useScrollPosition'
import useLogout from './hooks/useLogout'
import useCart from './hooks/useCart/index.jsx'
import useGoogleSheetsData from './hooks/useGoogleSheetsData'
import usePDFGeneration from './hooks/usePDFGeneration'
import useMenu from './hooks/useMenu'
import MobileMenu from './components/MobileMenu'
import BackToTopButton from './components/BackToTop'
import UserInfo from './components/UserInfo'
import ClientName from './components/ClientName'
import EmptyCartMessage from './components/EmptyCart'
import CartButtons from './components/CartButtons'
import BackdropMobileMenu from './components/BackDropMenu';
import HeaderPrices from './components/Header';
import FooterPrices from './components/Footer'

import './index.css';

const apiKey = 'AIzaSyCQH30li8aNGz5o5Yi8X0HzX74GGW7KToI';
const spreadsheetId = '1LxyUiThf0ewup8Ffq_gF4CeYnQ5JIDqJG8bA6skIZZs';

const Prices = ({ onLogout }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { handleCloseMenu } = useMenu(isMenuOpen, setIsMenuOpen);
    const isMobileScreen = useMobileScreen();
    const shouldShowBackToTop = useScrollPosition();
    const handleLogout = useLogout(onLogout);
    const { cartItems, addToCart, clearCart, removeFromCart } = useCart();
    const { jsonData, measures, titleSheet, lastChange } = useGoogleSheetsData(spreadsheetId, apiKey);

    const printableContentRef = useRef(null);
    const { generatePDF, handlePDF } = usePDFGeneration(printableContentRef);

    const handleClearInput = () => {
        const input = document.querySelector('.container_client input');
        if (input) {
            input.value = ''; // Limpa o valor do input
        }
    };

    if (!jsonData) {
        return <LoadingComponent />;
    }

    return (
        <div className='content_prices' ref={printableContentRef}>
            <HeaderPrices generatePDF={generatePDF} />
            {!generatePDF && (
                <>
                    <div className='divider'></div>
                    <UserInfo titleSheet={titleSheet} lastChange={lastChange} handleLogout={handleLogout} />
                </>
            )}

            <ClientName generatePDF={generatePDF} />
            <MobileMenu
                isMenuOpen={isMenuOpen}
                handleCloseMenu={handleCloseMenu}
                jsonData={jsonData}
                measures={measures}
                addToCart={addToCart}
            />
            <main className='container container_price'>
                <aside className={!generatePDF ? 'left-pane' : 'left-pane hide-pdf'}>
                    {isMobileScreen && (
                        <div className={isMobileScreen && 'title-menu-mobile'}>
                            <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>☰ <strong>Materiais</strong></button>
                        </div>)
                    }
                    {!isMobileScreen && (
                        <>
                            <h3>Materiais</h3>
                            <div className='buttons-material'>
                                {jsonData.map((item, index) => (
                                    <div className='item-select' key={index}>
                                        <AutoCompleteForm
                                            data={item.data}
                                            sheetName={item.sheetName}
                                            index={index}
                                            measures={measures}
                                            addToCart={addToCart}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                </aside>
                <aside className='right-pane'>
                    <div className="cart_container" id="cartContent">
                        <div className='buttons_cart'>
                            <h3>Orçamento</h3>
                            {!generatePDF && <CartButtons handleClearInput={handleClearInput} cartItems={cartItems} clearCart={clearCart} handlePDF={handlePDF} />}
                        </div>
                        <div className={cartItems.length ? "cart_items" : "cart_items empty"}>
                            {cartItems.length > 0 ? (
                                <>
                                    {cartItems.map((item, index) => (
                                        <div key={index} className={index === 0 ? "cart_item first" : "cart_item"}>
                                            <div className="item_name roboto-bold">{item.sheetName + ' - ' + item.productName}</div>
                                            <div className="item_prices">
                                                {item.measures && item.prices && item.measures.map((measure, idx) => (
                                                    (measure !== "Observação") && (
                                                        <div key={idx} className="price_row">
                                                            <div className="measure">{measure}</div>
                                                            <div className="price">{item.prices[idx]}</div>
                                                        </div>
                                                    )
                                                ))}
                                                {item.hasObservation && isMobileScreen && <div className="observation mobile">Observação: {item.prices[5]}</div>}
                                                <button className={!generatePDF ? 'button-remove' : 'button-remove hide-pdf'} title="Remover" onClick={() => removeFromCart(index)}>
                                                    <RemoveIcon />
                                                </button>
                                            </div>
                                            {item.hasObservation && !isMobileScreen && <div className="observation">Observação: {item.prices[5]}</div>}
                                        </div>
                                    ))}

                                    <CalculateTotal cartItems={cartItems} isMobile={isMobileScreen} generatePDF={generatePDF} /></>
                            ) : (
                                <EmptyCartMessage isMobileScreen={isMobileScreen} />
                            )}
                        </div>

                        {shouldShowBackToTop && <BackToTopButton onClick={scrollToTop} />}
                    </div>
                </aside>
            </main>

            <BackdropMobileMenu isMenuOpen={isMenuOpen} />
            <div className='divider'></div>
            <FooterPrices />
        </div>
    );
};

export default Prices;