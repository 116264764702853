import React from 'react';

import './index.css'

const EmptyCartMessage = ({ isMobileScreen }) => {
    return (
        <div className="empty_cart_message">
            Selecione materiais {isMobileScreen ? "acima" : "ao lado"} para começar um orçamento.
        </div>
    );
};

export default EmptyCartMessage;
