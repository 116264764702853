import { Whatsapp, Phone, Mail } from '../../../utils/icons.jsx'
import './index.css'

const FooterPrices = () => {
    return <footer className='footer_prices'>
        <div className='content_footer'>
            <div>
                <span className='razao_social'>FOCAL TEXTIL FABRICAÇÃO DE TECIDOS ESPECIAIS E FIOS SINTETICOS LTDA</span>
                <span>CPNJ: 10.620.811/0001-49</span>
                <span>AV. Alberto Rodrigues Alves 261 - CEP 14406-077</span>
                <span>Distrito Industrial - Franca-SP</span>
            </div>
            <div>
                <span className='mail_contact'><Mail /> contato@focaltextil.com.br</span>
                <span className='phone_contact'><Phone /> Telefone: (16)3727-0059</span>
                <span className='whatsapp_contact'><Whatsapp /> Whatsapp: (16)99999-4783</span>
            </div></div>

    </footer>
}

export default FooterPrices;