import React from 'react';
import {BackToTop} from '../../../utils/icons.jsx'; // Importe o ícone ou componente necessário

import './index.css'

const BackToTopButton = ({ onClick }) => {
    return (
        <button title="Voltar ao topo" className="back-to-top" onClick={onClick}>
            <BackToTop /> 
        </button>
    );
};

export default BackToTopButton;