// useMobileScreen.js
import { useState, useEffect } from 'react';

const useMobileScreen = () => {
    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 1300);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 1300);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobileScreen;
};

export default useMobileScreen;
