import './index.css'

const LoadingComponent = () => {
    return <div className="loading-container">
        <div className="loading-text">Carregando tabela de preços...</div>
        <div className="loading-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <circle cx="50" cy="50" fill="none" stroke="#1d3f72" strokeWidth="5" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        repeatCount="indefinite"
                        dur="1s"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                    ></animateTransform>
                </circle>
            </svg>
        </div>
    </div>
}

export default LoadingComponent