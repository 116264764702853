// useCart.js
import { useState, useEffect } from 'react';

const useCart = () => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = ({ sheetName, rowIndex, productName, measures, prices }, isMobileScreen, setIsMenuOpen) => {
        const hasObservation = prices && prices.length >= 6;
        const newItem = {
            sheetName,
            rowIndex,
            productName,
            measures,
            prices,
            hasObservation
        };
        const updatedCartItems = [...cartItems, newItem];
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        if (isMobileScreen) {
            setIsMenuOpen(false);
        }
    };

    const clearCart = () => {
        setCartItems([]);
        localStorage.removeItem('cartItems');
        localStorage.removeItem('discountPercentage');
        localStorage.removeItem('clientName');
    };

    const removeFromCart = (indexToRemove) => {
        const updatedCartItems = cartItems.filter((item, index) => index !== indexToRemove);
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const updatedCartItems = parsedCartItems.map(item => ({
                ...item,
                measures: item.measures || [],
                prices: item.prices || []
            }));
            setCartItems(updatedCartItems);
        }
    }, []);

    return { cartItems, addToCart, clearCart, removeFromCart };
};

export default useCart;
