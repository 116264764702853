export const users = [
    {
        username: "admin",
        password: "focal261%$"
    },
    {
        username: "focal",
        password: "focal2024"
    },
    {
        username: "representante1",
        password: "focal2024"
    },
    {
        username: "representante2",
        password: "focal2024"
    },
    {
        username: "representante3",
        password: "focal2024"
    },
    {
        username: "representante4",
        password: "focal2024"
    },
    {
        username: "representante5",
        password: "focal2024"
    },
    {
        username: "representante6",
        password: "focal2024"
    },
    {
        username: "representante7",
        password: "focal2024"
    },
    {
        username: "representante8",
        password: "focal2024"
    },
    {
        username: "representante9",
        password: "focal2024"
    },
    {
        username: "representante10",
        password: "focal2024"
    },
    {
        username: "representante11",
        password: "focal2024"
    },
    {
        username: "representante12",
        password: "focal2024"
    },
    {
        username: "representante13",
        password: "focal2024"
    },
    {
        username: "representante14",
        password: "focal2024"
    },
    {
        username: "representante15",
        password: "focal2024"
    },
    {
        username: "representante16",
        password: "focal2024"
    },
    {
        username: "representante17",
        password: "focal2024"
    },
    {
        username: "representante18",
        password: "focal2024"
    },
    {
        username: "representante19",
        password: "focal2024"
    },
    {
        username: "representante20",
        password: "focal2024"
    }
];