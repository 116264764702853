import React, { useRef, useEffect } from 'react';
import './index.css';

const ClientName = ({generatePDF}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const savedText = localStorage.getItem('clientName');
        if (savedText) {
            inputRef.current.value = savedText;
            resizeInput();
        }
    }, []);

    const resizeInput = () => {
        if (inputRef.current) {
            inputRef.current.style.width = inputRef.current.value.length + "ch";
        }
    };

    const handleInputChange = (event) => {
        const newText = event.target.value;
        if (newText.trim() === "") {
            localStorage.removeItem('clientName');
        } else {
            localStorage.setItem('clientName', newText);
        }
        resizeInput();
    };

    return (
        <div className={generatePDF ? "container container_client custom-style" : "container container_client"}>
            <span>Cliente:</span>
            <input
                type="text"
                ref={inputRef}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default ClientName;